<script>
	import { onMount } from 'svelte';
	import { apiConfig, viewInfo, checkRole } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte"

	$viewInfo.funcName = '門市庫存管理'

	let color = 'light'
	let enableExport = true
	let radioGroup = 'previousMonth' ;
	let currentDate = new Date();
	let sdate = ''
	let edate = ''
	let isValidateDateRange = true
	let storeId = ''
	let isValidateStoreId = true
	let storeName = ''
	let dept = ''
	let zone = ''
	let excelUrl = ''
	let allDept = []
	let allZone = []
	let loading = false
	
	onMount(()=> {
		setQueryDates() 

		fetch(`${$apiConfig.apiUrl}/stock/filter?code=${$apiConfig.apiKey}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					allDept = d.info.departments
					allZone = d.info.zones
				} 
			})
			.catch(e => {
				console.log(e)
			})
	})

	// $:console.log('excelUrl' , excelUrl)

	/**
	 * 查詢門市
	 */
	function query() {
		if(isValidateDateRange === false || isValidateStoreId === false ){ return false }
		
		loading = true

		fetch(`${$apiConfig.apiUrl}/stock/report?code=${$apiConfig.apiKey}&sdate=${sdate}&edate=${edate}&id=${storeId}&name=${storeName}&dept=${dept}&zone=${zone}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					excelUrl = d.info.url
					loading = false
					
					fetch(excelUrl)
						.then(response => response.arrayBuffer())
						.then(data => {
							const workbook = XLSX.read(data, { type: 'array' });
							renderExcelSheet(workbook);
						})
						.catch(error => {
							console.error('Error loading Excel file:', error);
						});
				} 
				enableExport = true
			})
			.catch(e => {
				console.log(e)
			})
	}

	/**
	 * 匯出報表
	 */
	 function exportReport(){
		location.href = excelUrl
	}

	function renderExcelSheet(workbook) {
		const sheetName = workbook.SheetNames[0];
		const worksheet = workbook.Sheets[sheetName];
		const htmlOutput = XLSX.utils.sheet_to_html(worksheet);

		const excelViewer = document.getElementById('excel-viewer');
		excelViewer.innerHTML = htmlOutput;
	}
	
	function setQueryDates() {
		currentDate = new Date();

		if(radioGroup === 'previousMonth') {
			var previousMonthDates = getPreviousMonthDates(currentDate);
			sdate = previousMonthDates.startDate.toLocaleDateString()
			edate = previousMonthDates.endDate.toLocaleDateString()
		}

		if(radioGroup === 'previousThreeMonths') {
			var previousThreeMonthsDates = getPreviousThreeMonthsDates(currentDate);
			sdate = previousThreeMonthsDates.startDate.toLocaleDateString()
			edate = previousThreeMonthsDates.endDate.toLocaleDateString()
		}

		validateDateRange()
	}

	/**
	 * 取得前一個月起迄日期
	 */
	function getPreviousMonthDates(currentDate) {
		// console.log('currentDate', currentDate)
		currentDate.setMonth(currentDate.getMonth() - 1);
		const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
		const firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
		const lastDayOfPreviousMonth = new Date(firstDayOfNextMonth - (24 * 60 * 60 * 1000));

		return {
			startDate: firstDayOfPreviousMonth,
			endDate: lastDayOfPreviousMonth
		};
	}

	/**
	 * 取得前三個月起迄日期
	 */
	function getPreviousThreeMonthsDates(currentDate) {
		var previousMonthDates = getPreviousMonthDates(currentDate);
		var previousTwoMonthDates = getPreviousMonthDates(previousMonthDates.startDate);
		var previousThreeMonthDates = getPreviousMonthDates(previousTwoMonthDates.startDate);

		return {
			startDate: previousThreeMonthDates.startDate,
			endDate: previousMonthDates.endDate
		};
	}

	/**
	 * 檢核日期
	 */
	function validateDateRange() {
		var startTimestamp = new Date(sdate).getTime();
		var endTimestamp = new Date(edate).getTime();
		var difference = Math.abs(endTimestamp - startTimestamp);
		var differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));

		// 檢查區間是否超過100天
		isValidateDateRange = differenceInDays <= 100
	}

	/**
	 * 檢核店號
	 */
	function validateStoreId() {
		if(storeId === ''){ isValidateStoreId = true; return false; }
		if(storeId.length !== 6 ){ isValidateStoreId = false; return false;}
	
		const numericRegex = /^-?\d+\.?\d*$/;
		isValidateStoreId =  numericRegex.test(storeId);
	}
</script>

<style>
.loader {
  margin-left: auto; 
  margin-right: auto;
  width: 80px;
  height: 60px;
  box-sizing: border-box;
  background:
    linear-gradient(#fff 0 0) left /calc(50% - 15px) 8px no-repeat,
    linear-gradient(#fff 0 0) right/calc(50% - 15px) 8px no-repeat,
    conic-gradient(from 135deg at top,#0000, red 1deg 90deg,#0000 91deg) bottom/14px 8px repeat-x,
    #000;
  border-bottom: 2px solid red;
  position: relative;
  overflow: hidden;
  animation: l6-0 1s infinite linear;
}
.loader::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 14px;
  background: lightblue;
  left: -5px;
  animation:
    l6-1 2s infinite cubic-bezier(0,100,1,100), 
    l6-2 2s infinite linear;
}
@keyframes l6-0{
  50% { background-position: left,right,bottom -2px left -4px}
}
@keyframes l6-1{
  0%,27%   {bottom: calc(50% + 4px)}
  65%,100% {bottom: calc(50% + 4.1px)}
}
@keyframes l6-2{
  100% {left:100%}
}
#excel-viewer > :global(table) {
	width: 100%;
}
:global(tr):hover {
	background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
</style>

<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={query}
					>  <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>
					<div class="flex flex-wrap">
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									通路別
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									>
									<option value="0">統一超商</option>
								</select>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-2">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-name">
									店號
								</label>
								<input id="grid-address" type="text" placeholder="輸入完整店號"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={storeId} on:change={validateStoreId} maxlength="6"/>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-name">
									門市
								</label>
								<input id="grid-address" type="text" placeholder="輸入關鍵字"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={storeName}/>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									區課
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={dept}>

									<option value="">(全部)</option>
									{#each allDept as item}
									<option value={item}>
										{item}
									</option>
									{/each}
								</select>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-name">
									ZONE
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={zone}>
									<option value="">(全部)</option>
									{#each allZone as item}
									<option value={item}>
										{item}
									</option>
									{/each}
								</select>
							</div>
						</div>

						<div class="flex items-center pl-3">
							<input id="horizontal-list-radio-license" type="radio" bind:group={radioGroup} name="radioGroup" value={'previousMonth'} on:change={setQueryDates}
								class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
							<label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">前一個月</label>
						</div>
						<div class="flex items-center pl-3">
							<input id="horizontal-list-radio-license" type="radio" bind:group={radioGroup} name="radioGroup" value={'previousThreeMonths'} on:change={setQueryDates}
								class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
							<label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">近三個月</label>
						</div>
						<div class="flex items-center pl-3">
							<input id="horizontal-list-radio-license" type="radio" bind:group={radioGroup} name="radioGroup" value={'costum'}
								class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
							<label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">自訂</label>
						</div>

						{#if radioGroup === 'costum'}
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="sdate">
								開始日期
								</label>
								<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									id="sdate" type="date" bind:value={sdate} on:change={validateDateRange} />
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="edate">
									結束日期
								</label>
								<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									id="edate" type="date" bind:value={edate} on:change={validateDateRange} />
							</div>
						</div>
						{/if}
					</div>

					{#if isValidateDateRange === false}
					<div class="text-red-400 mb-3 font-bold">
						<small>起迄日期區間不可超過 100 天</small>
					</div>
					{/if}

					{#if isValidateStoreId === false}
					<div class="text-red-400 mb-3 font-bold">
						<small>店號必須為6碼數字</small>
					</div>
					{/if}
				</form>
			</div>
		</div>
	</div>

	<div class="w-full mb-12 px-4" style="visibility:{excelUrl === '' && loading === false ? 'hidden' : 'visible'} ;">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-blueGray-800 text-white'}"
		>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h3 class="text-blueGray-700 text-xl font-bold">查詢結果</h3>
					{#if excelUrl !== ''}
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={exportReport}
					>  <i class="fas fa-download"></i> 
						匯出
					</button>
					{/if}
				</div>
			</div>

			{#if loading === true  || excelUrl === ''}
			<div class="block w-full overflow-x-auto" style="margin-bottom: 2em;">
				<div class="loader"></div>
			</div>
			{:else}
			<div class="block w-full overflow-x-auto">
				<small class="p-5 text-xs text-center text-teal-500">*服務門市Y/N 依最新循環杯門市資料檔為準</small>
				<div id="excel-viewer" class="p-5 text-xs text-center"></div>
			</div>
			{/if}
		</div>
	</div>
</div>